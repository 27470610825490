//
// Colours
//

$brand-primary: #442769;
$brand-secondary: #FFCB1C;
$brand-green: #1DAD87;
$brand-red: #EB4839;


$donate-colour: $brand-primary;

// Create greyscale
$black: #000 !default;
$grey-dark: #292b2c !default;
$grey: #464a4c !default;
$grey-light: #636c72 !default;
$grey-lighter: #eceeef !default;
$grey-lightest: #f7f7f9 !default;
$white: #fff !default;

//
// Layout
//

$container-max-width: 1400px;

$border-radius: 1rem;

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

//
// Logo
//

$logo: 'logo.png';
$logo-width: 300px !default;

//
// Typography
//

$font-size-base: 1.1rem;

$font-family-base: 'proxima-soft', 'Nunito', sans-serif;
// $font-family-base: "proxima-soft", sans-serif;

$font-weight-bold: bold !default;

//
// Buttons
//

$btn-font-weight: $font-weight-bold;
$btn-border-radius: 4rem;

//
// Header
//

$header-background-colour: $brand-primary;

// Header content

$header-content-margin-top: 0;
$header-content-margin-bottom: 0;

//
// Navigation
//

$nav-type: normal; // normal, flyout, fullscreen // TODO: mega
$nav-background-colour: $brand-primary;
$nav-normal-margin-bottom: 0;
$nav-normal-align-items: center;

// Top level items
$nav-top-level-item-colour: text-contrast($brand-primary);
$nav-top-level-chevrons-enabled: true;
$nav-top-level-item-font-size: 1rem;

// Submenus
$nav-submenu-background-colour: $brand-secondary;
$nav-submenu-item-colour: text-contrast($brand-secondary);
$nav-submenu-item-font-size: 1rem;

//
// Carousel
//



$carousel-max-width: 100%;
$carousel-details-background-colour: rgba(#fff, 0);
$carousel-details-max-width: 550px;
$carousel-details-position-y: center;
$carousel-details-padding: 5rem;
$carousel-details-border-radius: 0;

$carousel-heading-colour: $brand-secondary;

$carousel-read-more-background-colour: $brand-secondary;
$carousel-read-more-border-colour: $brand-secondary;
$carousel-read-more-colour: $brand-primary;
$carousel-summary-colour: $white;
$carousel-image-overlay: linear-gradient(to right, $brand-primary 30%, transparent 70%); 

$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: 20px;
$carousel-controls-button-background-colour: $carousel-details-background-colour;
$carousel-controls-icon-colour: $brand-primary;

$carousel-border-radius: 0;

//
// Home intro
//

$home-intro-margin-top: 0;
$home-intro-padding-y: 150px;
$home-intro-border-radius: 0;

//
// Home features
//

$home-features-padding-top: 0;

//$home-features-cards-entry-animation-prefab: 2;

//
// Impact stats
//

$impact-stats-heading-margin-bottom: $spacer;
$impact-stats-padding-y: $spacer * 3;

//
// Home feeds
//

// $home-feeds-cards-entry-animation-prefab: 2;

// $home-feeds-padding-top: 0;

// Event feed items
$feed-item-event-location-enabled: false;
$event-card-floating-date-background-colour: rgba($brand-primary, 0.8);
$event-card-floating-date-border-radius: $btn-border-radius;

//
// Donation form
//

$donation-form-frequency-tabs: true;

//
// Quick giving panel
//
//$quick-giving-type: columns;
$quick-giving-donation-amount-background-colour: $brand-secondary;
$quick-giving-donation-amount-selected-background-colour: $donate-colour;

$home-quick-giving-donation-amount-background-colour: $quick-giving-donation-amount-background-colour;
$home-quick-giving-donation-amount-selected-background-colour: $quick-giving-donation-amount-selected-background-colour;

//
// Blockquote
//
$blockquote-background-colour: $brand-primary;
$blockquote-colour: white;
$blockquote-padding: $spacer * 3;

//
// Card
//

$card-details-background-colour: $brand-primary;
$card-heading-colour: text-contrast($card-details-background-colour);
$card-hover-image-opacity: 1.0;
$card-hover-image-scale: 1.1;

// Event cards
$event-card-floating-date: true;

//
// Footer
//

$footer-boxes-count: 5;
$footer-prefab: 1;
$footer-background-colour: $grey-dark;
$footer-link-colour: text-contrast($footer-background-colour);



// Keeping In Touch
$keeping-in-touch-enabled: true;
$keeping-in-touch-via-email-enabled: true;
$keeping-in-touch-via-telephone-enabled: false;
$keeping-in-touch-via-sms-enabled: true;
$keeping-in-touch-via-post-enabled: false;

$sidebar-layout: below;